<template>
  <div>
    <div class="mb-4">
      <v-btn :to="{name: 'permissionList'}" text
        style="color: #ff9800">
        <v-icon>mdi-arrow-left</v-icon>
        <span> Back</span>
      </v-btn>
    </div>
    <v-card>
      <v-card-title>{{ dataItem.keyword }}</v-card-title>
      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "PermissionView",
  beforeRouteEnter(to, from, next) {
    if ('id' in to.params) {
      next()
    } else {
      next({name: 'permissionList'})
    }
  },
  data() {
    return {
      dataItem: {}
    }
  },
  created() {
    let id = this.$route.params.id
    this.$http.get(`Permission/getPermission?permissionId=${id}`).then((res)=>{
      if (res && res.result) {
        this.dataItem = res.result
      } else {
        this.$router.push({name: 'permissionList'})
      }
    }).catch(e => {
      console.log(e)
      this.$router.push({name: 'permissionList'})
    })
  }
}
</script>

<style scoped>

</style>
